import './styles/App.css';
import React, {useEffect, useMemo} from 'react';
import WebFont from 'webfontloader';

const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

function App() {
  const platform =
    window.navigator?.userAgentData?.platform || window.navigator.platform;
  const userAgent =
    window.navigator.userAgent || navigator.vendor || window.opera;

  const mode = window.location.pathname.split('/')[1];
  const id = window.location.pathname.split('/')[2];

  // const isInstagramInAppBrowser = userAgent.match(/Instagram/i);

  const currentPlatform = useMemo(() => {
    if (iosPlatforms.indexOf(platform) > -1) {
      return 'iOS';
    } else if (/Android/.test(userAgent)) {
      return 'Android';
    } else {
      return 'Other';
    }
  }, []);

  const redirectUrl = useMemo(() => {
    if (currentPlatform === 'iOS') {
      return 'itms-apps://apple.com/app/1573356486';
    } else if (currentPlatform === 'Android') {
      return 'https://play.google.com/store/apps/details?id=com.BLACKBIRD.Customer';
    } else {
      return 'https://blackbirdbordeaux.fr/';
    }
  }, [currentPlatform]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Inter:400,500,600,700'],
      },
    });
  }, []);

  if (!currentPlatform || !redirectUrl) {
    return null;
  }

  if (currentPlatform === 'Other') {
    window.location.replace(redirectUrl);
    return null;
  }

  if (!id) {
    window.location.replace('https://blackbirdbordeaux.fr/');
    return null;
  }

  return (
    <div className={'containerCenter'} style={{padding: 10, height: '100vh'}}>
      <div
        style={{
          backgroundColor: '#000000',
          color: 'white',
          padding: 10,
          borderRadius: 10,
        }}
        onClick={() => {
          window.location.replace(redirectUrl);
        }}>
        Télécharger Blackbird sur {currentPlatform}
      </div>

      <div style={{marginTop: 15, padding: 5}}>
        {"Vous possédez déjà l'application ?"}
      </div>

      <div
        style={{
          backgroundColor: '#000000',
          color: 'white',
          padding: 10,
          borderRadius: 10,
        }}
        onClick={() => {
          window.location.replace('blackbird://' + mode + '/' + encodeURI(id));
        }}>
        {mode === 'seller'
          ? "Accéder à l'établissement"
          : mode === 'group'
            ? 'Accéder à la commande groupée'
            : "Accéder à l'application"}
      </div>
    </div>
  );
}

export default App;
